import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          I am Shahzad, a <span className="purple">professional</span> based in the <span className="purple">UK</span>. 
          Holding a distinguished <span className="purple">Master's degree</span> in <span className="purple">Data Science</span>, 
          I bring over <span className="purple">4 years</span> of robust experience as a <span className="purple">full-stack developer</span> to the table. 
          Before diving into the intricacies of <span className="purple">data and code</span>, I navigated the dynamic landscape of <span className="purple">freelancing</span>.
<br/><br/>
          My commitment to precision and innovation extends beyond the digital realm. 
          Beyond the lines of <span className="purple">code</span>, you will find me engrossed in refining my skills, 
          whether it's:
          <ul>
            <li className="about-activity">
             <span className="purple"><ImPointRight /></span>  Mastering the intricacies of Web development,
            </li>
            <li className="about-activity">
             <span className="purple"><ImPointRight /></span>  Writing Tech Blogs,
            </li>
            <li className="about-activity">
            <span className="purple"><ImPointRight /></span> Seeking inspiration through travel.
            </li>
          </ul>
          <br/>
I approach every project with a <span className="purple">strategic mindset</span>, 
seeking not only functional solutions but ones that contribute meaningfully to the <span className="purple">overarching goals</span>. 
Let's connect and explore how my expertise can bring <span className="purple">value to your endeavors</span>.

          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Endeavor to create solutions that leave a lasting impact on the digital <span className="purple">landscape</span> and beyond."{" "}
          </p>
          <footer className="blockquote-footer">Shahzad</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
