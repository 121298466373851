import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import GNF from "../../Assets/Project 2 Shahzad Mukhtar.png";
import EMA from "../../Assets/Project 1 Shahzad Mukhtar.png";
import ISNET from "../../Assets/Project 3 Shahzad Mukhtar.png";
import CareerGrooms from "../../Assets/Project 4 Shahzad Mukhtar.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
        Explore a snapshot of my most recent projects below.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={GNF}
              isBlog={false}
              title="Glass and Fusion LTD"
              description="Introducing GlassFusionLtd, a dynamic e-commerce platform meticulously crafted with React.js and Node.js 
              technologies. This website serves a global clientele seeking exquisite stone and glass marble. 
              Clients can seamlessly navigate, filter, and add items to their carts. The customization feature allows users to specify 
              shapes and dimensions to view accurate pricing. On the admin side, a robust dashboard empowers easy product addition with
               variable pricing based on shapes and dimensions. Admins can effortlessly manage client orders, information, and even 
               dispatch quote prices using the integrated email system."
              ghLink=""
              demoLink="https://www.glassfusionltd.co.uk/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EMA}
              isBlog={false}
              title="Emma Farquharson Interiors"
              description="Presenting the elegantly crafted website for Emma Farquharson Interiors, a showcase of my expertise in WordPress development. Alongside building a custom theme to ensure a seamless and visually appealing user experience, I developed a bespoke plugin. This custom plugin streamlines the process of uploading and showcasing projects on the website, demonstrating a tailored solution to meet the specific needs of the client. Explore the site here to witness the harmonious blend of design and functionality."
              ghLink=""
              br="true"
              demoLink="https://emmafarquharson-interiors.co.uk/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ISNET}
              isBlog={false}
              title="Isenet"
              description="Introducing Isenet.co, a cutting-edge website designed and developed by yours truly. Specializing in server and domain registration, this platform seamlessly integrates a user-friendly front end with robust API functionalities and security measures. Users can effortlessly navigate and explore a range of services, from domain purchases to cloud hosting. Dive into the digital realm at Isenet.co to experience the convergence of sleek design, powerful APIs, and top-notch security protocols, reflecting my commitment to excellence in web development."
              ghLink=""
              demoLink="https://isenet.co/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={CareerGrooms}
              isBlog={false}
              title="Career Grooms"
              description="Presenting the Career Grooms International website, a testament to my PHP expertise and dedication to creating seamless user experiences. This bespoke platform features a custom admin panel, allowing for effortless job management. Users can explore available opportunities within the equine industry and conveniently submit applications. The site stands as a testament to my commitment to delivering functional and user-centric web solutions."
              ghLink=""
              demoLink=""
            />
          </Col>

         
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
