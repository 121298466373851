import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineMail
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            <span className="purple"> Howdy! </span>🚀, LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            I'm your friendly neighborhood code-wrangler, got the skills to play with <br/>
            <span className="purple">JavaScript </span>, 
            <span className="purple"> React</span>, 
            <span className="purple"> Node.js</span>, 
            <span className="purple"> Angular</span>, 
            <span className="purple"> Laravel</span>, 
            <span className="purple"> PHP</span>, 
            <span className="purple"> WordPress</span>, and 
            <span className="purple"> MySQL</span>.
              
              <br />
              <br />
              I've been known to turn wild ideas into even wilder lines of <b className="purple">code</b>, 
              creating web experiences that are as seamless as my love for a <b className="purple">good cup of coffee</b>. &nbsp;
              <br />
              <br />
              From crafting <b className="purple">React.js</b> magic on the front-end to conjuring robust spells with  <b className="purple">Node.js on the 
              server-side</b>, I'm your go-to <b className="purple">tech wizard</b>. 
              <br /><br />
              Let's sprinkle some coding charm on your digital dreams—because 
              <i>
                <b className="purple">
                  {" "}
                  life's too short for boring websites! 🧙‍♂️✨ 
                </b>
              </i>
              &nbsp; 
             
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>EXPLORE WITH ME</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              {/* <li className="social-icons">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://twitter.com/shahzadmukhtarr"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/mukhtar-shahzad/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/official.shahzadmukhtar/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:mukhtarshahzad23@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
             
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
